<template>
  <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
    <v-form @submit.prevent="passes(login)">
      <v-card flat>
        <v-card-title>
          <span class="headline mx-4">Editar datos personales</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="doc_tipo_id" name="Tipo Documento" rules="required" v-slot="{ errors, valid }">
                  <v-select
                    autofocus
                    v-model="item.doc_tipo_id"
                    :items="tiposDocumento"
                    item-value="id"
                    item-text="descripcion"
                    filled
                    dense
                    label="Tipo Documento"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="doc_numero" name="Número de Documento" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    v-model="item.doc_numero"
                    label="Número de Documento"
                    filled
                    dense
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="apellidos" name="Apellidos" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.apellidos"
                    label="Apellidos"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="nombres" name="Nombres" rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.nombres"
                    label="Nombres"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="datepickerFechaNacimiento"
                  v-model="datepickerFechaNacimiento"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ /*on*/ }">
                    <ValidationProvider name="Fecha Nacimiento" rules="" v-slot="{ errors, valid }">
                      <v-text-field
                        type="date"
                        ref="fechaNacimiento"
                        v-model="item.fecha_nacimiento"
                        filled
                        dense
                        label="Fecha Nacimiento"
                        prepend-icon="mdi-calendar"
                        :error-messages="errors"
                        :success="valid"
                        v-on:click:prepend="openDatepickerFechaNacimiento"
                        v-on:keydown.enter.stop.prevent="openDatepickerFechaNacimiento"
                      ></v-text-field>
                    </ValidationProvider>
                  </template>
                  <v-date-picker
                    v-model="item.fecha_nacimiento"
                    scrollable
                    @change="closeDatepickerFechaNacimiento"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="email" name="E-mail" rules="email" v-slot="{ errors, valid }">
                  <v-text-field
                    type="email"
                    filled
                    v-model="item.email"
                    label="E-mail"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <!--v-col cols="12" sm="6">
                <ValidationProvider vid="telefono_fijo" name="Teléfono Fijo" rules="" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.telefono_fijo"
                    label="Teléfono Fijo"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col-->
              <v-col cols="12" sm="6">
                <ValidationProvider vid="telefono_movil" name="Teléfono Móvil" rules="" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.telefono_movil"
                    label="Teléfono Móvil"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="domicilio" name="Domicilio" rules="" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.domicilio"
                    label="Domicilio"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="localidad" name="Localidad" rules="" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.localidad"
                    label="Localidad"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider vid="obra_social_id" name="Obra Social" rules="required" v-slot="{ errors, valid }">
                  <v-select
                    v-model="item.obra_social_id"
                    :items="obrasSociales"
                    item-value="id"
                    item-text="nombre"
                    filled
                    label="Obra Social"
                    required
                    :error-messages="errors"
                    :success="valid"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>

            <v-row>
              <!--v-col cols="12" sm="6">
                <ValidationProvider vid="pais" name="País" rules="" v-slot="{ errors, valid }">
                  <v-text-field
                    filled
                    v-model="item.pais"
                    label="País"
                    :error-messages="errors"
                    :success="valid"
                  ></v-text-field>
                </ValidationProvider>
              </v-col-->
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="mx-4">
          <v-spacer></v-spacer>
          <v-btn text :to="{ name: 'home' }" :disabled="saving">Cancelar</v-btn>
          <v-btn type="submit" @click.prevent="passes(save)" color="primary"
              dark :loading="saving">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate"

export default {
  name: 'DatosPersonales',

  components: {
    ValidationProvider,
    ValidationObserver
  },

  data: () => ({
    loading: false,
    saving: false,
    item: {
      doc_tipo_id: null,
      doc_numero: '',
      apellidos: '',
      nombres: '',
      fecha_nacimiento: null,
      telefono_fijo: '',
      telefono_movil: '',
      email: '',
      domicilio: '',
      localidad: '',
      provincia: '',
      pais: '',
      obra_social_id: null,
    },
    defaultItem: {
      doc_tipo_id: null,
      doc_numero: '',
      apellidos: '',
      nombres: '',
      fecha_nacimiento: null,
      telefono_fijo: '',
      telefono_movil: '',
      email: '',
      domicilio: '',
      localidad: '',
      provincia: '',
      pais: '',
      obra_social_id: null,
    },
    tiposDocumento: [],
    obrasSociales: [],
    datepickerFechaNacimiento: false,
  }),

  methods: {
    save: function () {
      this.saving = true

      this.$http.put('pacientes/'+this.item.id, this.item)
      .then(() => {
        this.$eventHub.$emit('snackbar-message', 'Los datos fueron actualizados!', 'success')
      })
      .catch((error) => {
        switch (error.response.status) {
          case 422:
            this.$eventHub.$emit('snackbar-message', 'Hay errores en el formulario', 'error')

            this.$refs.obs.setErrors(error.response.data.errors);

            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
      .finally(() => {
        this.saving = false
      })
    },

    fetch: function () {
      this.$http.get('pacientes/'+this.$store.state.paciente)
        .then((response) => {
          this.item = response.data
        })
        .catch(() => {
          this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        })
    },

    resetForm: function () {
      this.$refs.obs.reset()
      this.item = {...this.defaultItem}
    },

    fetchTiposDocumentos: function () {
      return this.$http.get('tipos-documento')
      .then((response) => {
        this.tiposDocumento = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    fetchObrasSociales: function () {
      return this.$http.get('obras-sociales')
      .then((response) => {
        this.obrasSociales = response.data
      })
      .catch((error) => {
        switch (error.response.status) {
          case 401:
            break;
          default:
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
        }
      })
    },

    openDatepickerFechaNacimiento: function () {
      this.datepickerFechaNacimiento = true
    },

    closeDatepickerFechaNacimiento: function () {
      this.datepickerFechaNacimiento = false

      setTimeout(() => {
        this.$refs.fechaNacimiento.$el.querySelector('input').focus()
      }, 10);
    },
  },

  created () {
    Promise.all([
      this.fetchTiposDocumentos(),
      this.fetchObrasSociales(),
    ]).then(() => {
      this.fetch()
    })
  }
};
</script>
